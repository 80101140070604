// Users
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';

// Email
export const GET_EMAIL_SITES_SUCCESS = 'GET_EMAIL_SITES_SUCCESS';
export const GET_EMAIL_X1_SITES_SUCCESS = 'GET_EMAIL_X1_SITES_SUCCESS';
export const GET_EMAIL_SITE_SYNC_STATUS_SUCCESS = 'GET_EMAIL_SITE_SYNC_STATUS_SUCCESS';
export const GET_EMAIL_X1_SITE_SYNC_STATUS_SUCCESS = 'GET_EMAIL_X1_SITE_SYNC_STATUS_SUCCESS';
export const GET_EMAIL_SITE_CONFIG_SUCCESS = 'GET_EMAIL_SITE_CONFIG_SUCCESS';
export const GET_EMAIL_PROFILE_STATISTICS_SUCCESS = 'GET_EMAIL_PROFILE_STATISTICS_SUCCESS';
export const GET_EMAIL_PROFILE_ACTIONS_SUCCESS = 'GET_EMAIL_PROFILE_ACTIONS_SUCCESS';
export const SET_FAILED_OBJECTS_SEARCH_CRITERIA = 'SET_FAILED_OBJECTS_SEARCH_CRITERIA';
export const SET_FAILED_OBJECT_TAG = 'SET_FAILED_OBJECT_TAG';
export const SET_EMAIL_FAILED_OBJECTS = 'SET_EMAIL_FAILED_OBJECTS';
export const SET_FAILED_OBJECT_TYPES = 'SET_FAILED_OBJECT_TYPES';
export const SHOW_DETAIL_OBJECT = 'SHOW_DETAIL_OBJECT';

// Email Epsilon X1 - Support for EU and prAna go live
export const GET_EMAIL_SITES_SUCCESS_EPSILON = 'GET_EMAIL_SITES_SUCCESS_EPSILON';
export const GET_EMAIL_PROFILE_STATISTICS_SUCCESS_EPSILON = 'GET_EMAIL_PROFILE_STATISTICS_SUCCESS_EPSILON';
export const GET_EMAIL_SITE_CONFIG_SUCCESS_EPSILON = 'GET_EMAIL_SITE_CONFIG_SUCCESS_EPSILON';
export const GET_EMAIL_SITE_SYNC_STATUS_SUCCESS_EPSILON = 'GET_EMAIL_SITE_SYNC_STATUS_SUCCESS_EPSILON';
export const GET_EMAIL_PROFILE_ACTIONS_SUCCESS_EPSILON = 'GET_EMAIL_PROFILE_ACTIONS_SUCCESS_EPSILON';

//Inventory
export const GET_ALL_INVENTORY_LIST_SUCCESS = 'GET_ALL_INVENTORY_LIST_SUCCESS';
export const GET_ALL_INVENTORY_LIST_FAILED = 'GET_ALL_INVENTORY_LIST_FAILED';
export const SEARCH_INVENTORY_LIST_SUCCESS = 'SEARCH_INVENTORY_LIST_SUCCESS';
export const SEARCH_INVENTORY_LIST_FAILED = 'SEARCH_INVENTORY_LIST_FAILED';
export const GET_INVENTORY_RECORDS_SUCCESS = 'GET_INVENTORY_RECORDS_SUCCESS';
export const GET_INVENTORY_RECORDS_FAILED = 'GET_INVENTORY_RECORDS_FAILED';
export const LOAD_MORE_INVENTORY_RECORDS_SUCCESS = 'LOAD_MORE_INVENTORY_RECORDS_SUCCESS';
export const SEARCH_PRODUCT_INVENTORY_RECORD_SUCCESS = 'SEARCH_PRODUCT_INVENTORY_RECORD_SUCCESS';
export const SEARCH_PRODUCT_INVENTORY_RECORD_FAILED = 'SEARCH_PRODUCT_INVENTORY_RECORD_FAILED';
export const UPDATE_PRODUCT_INVENTORY_RECORD_SUCCESS = 'UPDATE_PRODUCT_INVENTORY_RECORD_SUCCESS';
export const UPDATE_PRODUCT_INVENTORY_RECORD_FAILED = 'UPDATE_PRODUCT_INVENTORY_RECORD_FAILED';
export const SUCCESSFULLY_ALERTED = 'SUCCESSFULLY_ALERTED';

// NOTLIVE
export const GET_NOTLIVE_SITES_SUCCESS = 'GET_NOTLIVE_SITES_SUCCESS';
export const GET_NOTLIVE_SITE_SYNC_STATUS_SUCCESS = 'GET_NOTLIVE_SITE_SYNC_STATUS_SUCCESS';
export const GET_NOTLIVE_SITE_CONFIG_SUCCESS = 'GET_NOTLIVE_SITE_CONFIG_SUCCESS';
export const GET_NOTLIVE_PROFILE_STATISTICS_SUCCESS = 'GET_NOTLIVE_PROFILE_STATISTICS_SUCCESS';
export const GET_NOTLIVE_PROFILE_ACTIONS_SUCCESS = 'GET_NOTLIVE_PROFILE_ACTIONS_SUCCESS';

// Logs
export const SEARCH_LOGS_SUCCESS = 'SEARCH_LOGS_SUCCESS';
export const SEARCH_LOGS_FAILED = 'SEARCH_LOGS_FAILED';

// Vip
export const GET_VIP_STATS_SUCCESS = 'GET_VIP_STATS_SUCCESS';
export const GET_VIP_STATS_FAILED = 'GET_VIP_STATS_FAILED';
export const SIMPLE_SEARCH_SUCCESS = 'SIMPLE_SEARCH_SUCCESS';
export const SEARCH_FAILED = 'SEARCH_FAILED';
export const GET_VIP_CUSTOMER_SUCCESS = 'GET_VIP_CUSTOMER_SUCCESS';
export const GET_VIP_CUSTOMER_FAILED = 'GET_VIP_CUSTOMER_FAILED';
export const GET_VIP_CUSTOMER_MEDIA_SUCCESS = 'GET_VIP_CUSTOMER_MEDIA_SUCCESS';
export const GET_VIP_CUSTOMER_MEDIA_FAILED = 'GET_VIP_CUSTOMER_MEDIA_FAILED';
export const DELETE_VIP_CUSTOMER_SUCCESS = 'DELETE_VIP_CUSTOMER_SUCCESS';
export const DELETE_VIP_CUSTOMER_FAILED = 'DELETE_VIP_CUSTOMER_FAILED';
export const UPDATE_VIP_CUSTOMER_SUCCESS = 'UPDATE_VIP_CUSTOMER_SUCCESS';
export const UPDATE_VIP_CUSTOMER_FAILED = 'UPDATE_VIP_CUSTOMER_FAILED';
export const GET_CUSTOMER_GROUPS_SUCCESS = 'GET_CUSTOMER_GROUPS_SUCCESS';
export const GET_CUSTOMER_GROUPS_FAILED = 'GET_CUSTOMER_GROUPS_FAILED';
export const GET_VIP_SITES_SUCCESS = 'GET_VIP_SITES_SUCCESS';
export const GET_VIP_SITES_FAILED = 'GET_VIP_SITES_SUCCESS';
export const SET_SITE_ID = 'SET_SITE_ID';
export const GET_VIP_ATTACHMENT_URL_SUCCESS = 'GET_VIP_ATTACHMENT_URL_SUCCESS';
export const GET_VIP_ATTACHMENT_URL_FAILED = 'GET_VIP_ATTACHMENT_URL_FAILED';
export const SET_VIP_QUERY = 'SET_VIP_QUERY';

// Utils
export const TRANSLATE_TEXT_SUCCESS = 'TRANSLATE_TEXT_SUCCESS';
export const TRANSLATE_TEXT_FAILED = 'TRANSLATE_TEXT_FAILED';
export const GET_SWAGGER_URLS_SUCCESS = 'GET_SWAGGER_URLS_SUCCESS';
export const GET_SWAGGER_URLS_FAILED = 'GET_SWAGGER_URLS_FAILED';
export const GET_SWAGGER_DOC_SUCCESS = 'GET_SWAGGER_DOC_SUCCESS';
export const GET_SWAGGER_DOC_FAILED = 'GET_SWAGGER_DOC_FAILED';
export const GET_LAMBDA_STATISTICS_SUCCESS = 'GET_LAMBDA_STATISTICS_SUCCESS';
export const GET_LAMBDA_STATISTICS_FAILED = 'GET_LAMBDA_STATISTICS_FAILED';
export const SET_GET_LAMBDA_STATISTICS_RECORDS_PER_PAGE = 'SET_GET_LAMBDA_STATISTICS_RECORDS_PER_PAGE';
export const SEO_LINT_SUCCESS = 'SEO_LINT_SUCCESS';
export const SEO_LINT_FAILED = 'SEO_LINT_FAILED';
export const GET_SERVICE_STATUS_SUCCESS = 'GET_SERVICE_STATUS_SUCCESS';
export const GET_AWS_SERVICES_SUCCESS = 'GET_AWS_SERVICES_SUCCESS';
export const GET_AWS_SERVICES_FAILED = 'GET_AWS_SERVICES_FAILED';
export const SET_AWS_SERVICES_SUCCESS = 'SET_AWS_SERVICES_SUCCESS';
export const GET_LOADER_STATUS = 'GET_LOADER_STATUS';
export const ENCRYPT_FAILED = 'ENCRYPT_FAILED';
export const ENCRYPT_SUCCESS = 'ENCRYPT_SUCCESS';
export const DECRYPT_FAILED = 'DECRYPT_FAILED';
export const DECRYPT_SUCCESS = 'DECRYPT_SUCCESS';
export const GET_SERVICES_LIST_SUCCESS = 'GET_SERVICES_LIST_SUCCESS';
export const GET_SERVICES_LIST_FAILED = 'GET_SERVICES_LIST_FAILED';
export const GET_SERVICE_CONFIGURATION_SUCCESS = 'GET_SERVICE_CONFIGURATION_SUCCESS';
export const GET_SERVICE_CONFIGURATION_FAILED = 'GET_SERVICE_CONFIGURATION_FAILED';

// Notify Me
export const SEARCH_NOTIFY_ME_RECORDS_SUCCESS = 'SEARCH_NOTIFY_ME_RECORDS_SUCCESS';
export const SEARCH_NOTIFY_ME_RECORDS_FAILED = 'SEARCH_NOTIFY_ME_RECORDS_FAILED';
export const GET_NOTIFY_ME_SITES_SUCCESS = 'GET_NOTIFY_ME_SITES_SUCCESS';
export const GET_NOTIFY_ME_SITES_FAILED = 'GET_NOTIFY_ME_SITES_FAILED';
export const SET_NOTIFY_ME_ITEMS_PER_PAGE = 'SET_NOTIFY_ME_ITEMS_PER_PAGE';
export const SET_NOTIFY_ME_SITE_ID = 'SET_NOTIFY_ME_SITE_ID';
export const GET_NOTIFY_ME_STATS_SUCCESS = 'GET_NOTIFY_ME_STATS_SUCCESS';
export const GET_NOTIFY_ME_STATS_FAILED = 'GET_NOTIFY_ME_STATS_FAILED';

// Order History/Status
export const SEARCH_ORDER_HISTORY_SUCCESS = 'SEARCH_ORDER_HISTORY_SUCCESS';
export const SEARCH_ORDER_HISTORY_FAILED = 'SEARCH_ORDER_HISTORY_FAILED';
export const GET_ORDER_HISTORY_COUNT_SUCCESS = 'GET_ORDER_HISTORY_COUNT_SUCCESS';
export const GET_ORDER_HISTORY_COUNT_FAILED = 'GET_ORDER_HISTORY_COUNT_FAILED';
export const GET_RECENT_ORDER_STATUS_SUCCESS = 'GET_RECENT_ORDER_STATUS_SUCCESS';
export const GET_RECENT_ORDER_STATUS_FAILED = 'GET_RECENT_ORDER_STATUS_FAILED';
export const SEARCH_ORDER_STATUS_SUCCESS = 'SEARCH_ORDER_STATUS_SUCCESS';
export const SEARCH_ORDER_STATUS_FAILED = 'SEARCH_ORDER_STATUS_FAILED';

// Photo Studio
export const GET_PHOTO_STUDIOS_SUCCESS = 'GET_PHOTO_STUDIOS_SUCCESS';
export const GET_PHOTO_STUDIOS_FAILED = 'GET_PHOTO_STUDIOS_FAILED';
export const UPDATE_MODEL_CODE_SUCCESS = 'UPDATE_MODEL_CODE_SUCCESS';
export const UPDATE_MODEL_CODE_FAILED = 'UPDATE_MODEL_CODE_FAILED';
export const SET_SELECTED_MODELS = 'SET_SELECTED_MODELS';
export const GET_MODELS_LIST_SUCCESS = 'GET_MODELS_LIST_SUCCESS';
export const GET_MODELS_LIST_FAIL = 'GET_MODELS_LIST_FAIL';
export const SET_PUBLISH_MULTIPLE_MODELS_SUCCESS = 'SET_PUBLISH_MULTIPLE_MODELS_SUCCESS';
export const SET_PUBLISH_MULTIPLE_MODELS_ERROR = 'SET_PUBLISH_MULTIPLE_MODELS_ERROR';
export const PUBLISH_MULTIPLE_MODELS_PENDING = 'PUBLISH_MULTIPLE_MODELS_PENDING';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';
export const UPDATE_PROFILE_PENDING = 'UPDATE_PROFILE_PENDING';
export const QUERY_MODEL_DATA = 'QUERY_MODEL_DATA';

//dashboard
export const GET_ENVIRONMENT_SUCCESS = 'GET_ENVIRONMENT_SUCCESS';
