import React, { Component } from 'react';
import { Paper } from 'material-ui';

import store from '../services/store';
import localStorage from '../services/localStorage';

import { Grid, Row, Col } from 'react-flexbox-grid';
import { MenuItem, RaisedButton, SelectField, TextField, Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui';

import InventoryBreadCrumbs from './InventoryBreadCrumbs';
import { InventoryRecordsSearchResults } from './InventoryRecordsSearchResults';
import { makeInventoryRequest } from '../api/inventoryApi';
import { getInventoryRecordsSuccess, getInventoryRecordsFailed } from '../actions/inventoryActions';

export const styles = {
  buttonMargin: {
    margin: "10px"
  }
};

export default class InventoryRecords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inventoryList: {},
      inventoryListId: '',
      inventoryRecords: [],
      productId: '',
      searchPhrase: '',
      start: 0,
      count: 100,
      c_bisnEnabled: 'all'
    };
  }

  componentDidMount() {
    let history = localStorage.get('history');
    let location = history && history.length ? history[history.length - 1] : null;
    let inventoryListId = location.split('/')[3];

    let state = store.getState().inventoryState;
    let inventoryList = state.inventoryList.data ? state.inventoryList.data.find(list => list.id === inventoryListId) : {};

    this.setState({ inventoryListId: inventoryListId, inventoryList: inventoryList });
  }

  handleTextChange = (e, text) => {
    let value = {};
    value[`${e.target.name}`] = text.length > 0 ? text : '';
    this.setState(value);
  };

  handleCountChange = (event, index, value) => {
    this.setState(Object.assign({}, this.state, { count: value }));
  };

  handleEnabledChange = (event, index, value) => {
    this.setState(Object.assign({}, this.state, { c_bisnEnabled: value }));
  }

  searchSubmit = () => {
    let body = { 
      inventoryListId: this.state.inventoryListId,
      c_bisnEnabled: this.state.c_bisnEnabled 
    };

    if (this.state.productId) {
      body.productIds = this.state.productId.replace(/\s/g, '').split(',');
    } else if (this.state.searchPhrase) {
      body.count = this.state.count;
      body.searchPhrase = this.state.searchPhrase;
      body.siteIds = this.state.inventoryList.assigned_sites;
      body.start = this.state.start;
    } else {
      body.start = this.state.start;
      body.count = this.state.count;
    }

    makeInventoryRequest(getInventoryRecordsSuccess, getInventoryRecordsFailed, `exportProduct/getProductInventoryRecords`, `post`, body)
  };

  render() {
    let toolbarTitle = `Search Inventory Records ${this.state.inventoryListId}`;
    return (
      <div style={{ paddingBottom: '20px' }}>
        <InventoryBreadCrumbs records={true} />
        <Paper>
          <div>
            <Toolbar style={styles.toolbarMargin}>
              <ToolbarGroup>
                <ToolbarTitle text={toolbarTitle} />
              </ToolbarGroup>
              <ToolbarGroup>
                <RaisedButton
                  primary={true}
                  label="Search"
                  style={styles.buttonMargin}
                  onClick={this.searchSubmit} />
              </ToolbarGroup>
            </Toolbar>
            <Grid fluid>
              <Row>
                <Col xs={12} md={2}>
                  <TextField
                    multiLine={true}
                    rows={3}
                    rowsMax={10}
                    hintText="List ID:"
                    floatingLabelText="List ID:"
                    value={this.state.inventoryListId}
                    disabled={true} />
                </Col>
                <Col xs={12} md={3}>
                  <TextField
                    multiLine={true}
                    rows={3}
                    rowsMax={10}
                    floatingLabelText="Product ID:"
                    floatingLabelFixed={true}
                    onChange={this.handleTextChange}
                    name="productId"
                    value={this.state.productId} />
                </Col>
                <Col xs={12} md={3}>
                  <TextField
                    multiLine={true}
                    rows={3}
                    rowsMax={10}
                    floatingLabelText="Search Product Name:"
                    floatingLabelFixed={true}
                    onChange={this.handleTextChange}
                    name="searchPhrase"
                    value={this.state.searchPhrase}
                    disabled={this.state.inventoryList && this.state.inventoryList.assigned_sites ? false : true} />
                </Col>
                <Col xs={12} md={2}>
                  <SelectField
                    floatingLabelText="Results per page"
                    value={this.state.count}
                    onChange={this.handleCountChange}>
                    <MenuItem value={10} primaryText="10" />
                    <MenuItem value={25} primaryText="25" />
                    <MenuItem value={50} primaryText="50" />
                    <MenuItem value={100} primaryText="100" />
                  </SelectField>
                </Col>
                <Col xs={12} md={2}>
                  <SelectField
                    floatingLabelText="c_bisnEnabled"
                    value={this.state.c_bisnEnabled}
                    onChange={this.handleEnabledChange}>
                    <MenuItem value={'all'} primaryText="All" />
                    <MenuItem value={'enabled'} primaryText="Enabled" />
                    <MenuItem value={'notEnabled'} primaryText="Not Enabled" />
                  </SelectField>
                </Col>
              </Row>
            </Grid>
          </div>
          <InventoryRecordsSearchResults c_bisnEnabled={this.state.c_bisnEnabled}/>
        </Paper>
      </div>
    );
  }
}
