/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MenuItem, RaisedButton, SelectField, Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui';
import { grey500 } from "material-ui/styles/colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AgGridReact } from 'ag-grid-react';
import { GridApi } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import "ag-grid-community/dist/styles/ag-theme-material.css";

import { makeInventoryRequest } from '../api/inventoryApi';
import { loadMoreInventoryRecordsSuccess, getInventoryRecordsFailed, successfullyAlerted, updateProductInventoryRecordSuccess, updateProductInventoryRecordFailed } from '../actions/inventoryActions';
import LoaderComponent from "../components/Loader";
import localStorage from '../services/localStorage';
import store from '../services/store';

export const styles = {
  link: {
    color: 'rgb(0, 136, 206)'
  },
  toolbar: {
    marginTop: '20px'
  },
  buttonMargin: {
    margin: "10px"
  },
  icon: {
    marginRight: '20px',
    color: grey500
  },
  tableFooter: {
    borderTop: `1px solid rgb(232, 232, 232)`,
    display: 'flex',
    justifyContent: 'space-between'
  },
  showEnabledSelect: {
    paddingLeft: '10px'
  },
  paginate: {
    textAlign: 'right',
    alignSelf: 'flex-end',
    padding: '10px'
  }
};

class InventoryRecordsResultsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inventoryListId: '',
      inventoryRecords: [],
      inventoryRecordsCopy: [],
      inventoryRecordUpdates: [],
      showOnlyEnabledFieldValue: "all",
      query: null,
      start: 0,
      count: 100
    };
  }

  componentDidMount() {
    if (!this.state.inventoryListId) {
      let state = localStorage.get('history');
      let location = state && state.length ? state[state.length - 1] : null;
      let inventoryListId = location.split('/')[3];
      this.setState({ inventoryListId: inventoryListId });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.updateSuccess || nextProps.error) {
      let alertMessage = nextProps.updateSuccess ? `Sucessfully updated records` : `Inventory Records error: ${nextProps.error.message}`;
      store.dispatch(successfullyAlerted());
      alert(alertMessage);
    }
    
    let start = (nextProps.start || nextProps.start === 0) ? nextProps.start : this.state.start;
    let count = nextProps.count ? nextProps.count : this.state.count;
    let total = nextProps.total || nextProps.inventoryRecords.length;
    this.setState({
      inventoryRecords: nextProps.inventoryRecords,
      count: count,
      start: start,
      total: total,
      showOnlyEnabledFieldValue: "all"
    });
  }

  notifyMeShowEnabledFields() {
    let buttonObj = [
      { value: "all", text: "All" },
      { value: "enabled", text: "Show Enabled"},
      { value: "notEnabled", text: "Show Not Enabled"}
    ];
    return buttonObj.map((obj, index) => <MenuItem key={index} value={obj.value} primaryText={obj.text} />);
  }

  handleChangeShowOnlyEnabled(event, index, values) {
    let inventoryRecords = this.state.inventoryRecords;
    let inventoryRecordsCopy = this.state.inventoryRecordsCopy;
    let inventoryRecordsAll = inventoryRecords.length >= inventoryRecordsCopy.length ? inventoryRecords : inventoryRecordsCopy;

    if (values === 'all') {
      inventoryRecords = inventoryRecordsAll;
      inventoryRecordsCopy = [];
    } else {
      inventoryRecordsCopy = inventoryRecordsAll;
      inventoryRecords = inventoryRecordsAll.length ? inventoryRecordsAll.filter(record => values === 'enabled' ? record.c_bisnEnabled : !record.c_bisnEnabled) : [];
    }
    this.setState({inventoryRecords, inventoryRecordsCopy, showOnlyEnabledFieldValue: values});
  }

  renderInventoryRecords() {
    let inventoryRecords = [];
    if (this.state.inventoryRecords.length) {
      inventoryRecords = this.state.inventoryRecords.map(record => {
        return {
          product_id: record.product_id,
          product_name: record.product_name,
          inventory_list_id: this.state.inventoryListId,
          link: record.link,
          c_bisnEnabled: record.c_bisnEnabled
        };
      });
    }
    return inventoryRecords;
  }

  onDataRendered(params) {
    this.gridApi = params.api;
    this.gridApi.forEachNode(function (node) {
      node.setSelected(node.data.c_bisnEnabled);
    });
  }

  rowSelected(event) {
    let recordUpdate = { ...event.data, c_bisnEnabled: event.node.isSelected() }
    let recordUpdateIndex = this.state.inventoryRecordUpdates.findIndex(record => record.product_id === event.data.product_id);
    let recordStateIndex = this.state.inventoryRecords.findIndex(record => record.product_id === event.data.product_id);

    // update inventory records state
    let inventoryRecords = [...this.state.inventoryRecords];
    inventoryRecords[recordStateIndex] = recordUpdate;

    // update inventory records being sent to server
    let inventoryRecordUpdates;
    if (recordUpdateIndex > -1) {
      inventoryRecordUpdates = [...this.state.inventoryRecordUpdates];
      inventoryRecordUpdates[recordUpdateIndex] = recordUpdate;
      this.setState({ inventoryRecords, inventoryRecordUpdates });
    } else {
      inventoryRecordUpdates = [...this.state.inventoryRecordUpdates, recordUpdate];
      this.setState({ inventoryRecords, inventoryRecordUpdates });
    }

    const focusedCell = event.node.gridApi.getFocusedCell();
    if (focusedCell) {
      event.node.gridApi.ensureIndexVisible(focusedCell.rowIndex);
    }
  }

  paginate() {
    let body = {
      inventoryListId: this.state.inventoryListId,
      start: this.state.start + this.state.count,
      count: this.state.count,
      c_bisnEnabled: this.props.c_bisnEnabled 
    };

    makeInventoryRequest(loadMoreInventoryRecordsSuccess, getInventoryRecordsFailed, `exportProduct/getProductInventoryRecords`, `post`, body);
  }

  updateProductInventoryRecord() {
    let body = { records: this.state.inventoryRecordUpdates };
    makeInventoryRequest(updateProductInventoryRecordSuccess, updateProductInventoryRecordFailed, `exportProduct/updateInventoryRecords`, `post`, body, this.state.inventoryRecords);
  }

  render() {

    let ColumnDefs = [
      { headerName: "Notify Me Enabled", flex: 1, suppressSorting: true, checkboxSelection: true, headerCheckboxSelection: true },
      { headerName: "Product ID", field: "product_id", flex: 1, unSortIcon: true, sort: "desc" },
      { headerName: "Product Name", field: "product_name", flex: 2, unSortIcon: true },
      { headerName: "Link", field: "link", flex: 3, unSortIcon: true },
    ];

    let inventoryRecords = this.renderInventoryRecords();
    let notifyMeShowEnabledFields = this.notifyMeShowEnabledFields();

    return (
      <div>
        <Toolbar style={styles.toolbar}>
          <ToolbarGroup>
            <FontAwesomeIcon icon={"tshirt"} style={styles.icon} size="2x" fixedWidth />
            <ToolbarTitle text="Product Inventory Record Results" />
          </ToolbarGroup>
          <ToolbarGroup>
            <LoaderComponent />
          </ToolbarGroup>
          <ToolbarGroup>
            <RaisedButton primary={true} label="Update" onClick={() => this.updateProductInventoryRecord()} style={styles.buttonMargin} />
          </ToolbarGroup>
        </Toolbar>
        <div className="ag-theme-material" style={{ height: '580px' }}>
          <AgGridReact
            ref="agGrid"
            defaultColDef={{ sortable: true, resizable: true, cellStyle: { display: 'flex', justifyContent: 'left', alignItems: 'center' } }}
            columnDefs={[...ColumnDefs]}
            rowData={inventoryRecords}
            accentedSort={true}
            rowHeight={50}
            headerHeight={50}
            rowSelection={'multiple'}
            rowMultiSelectWithClick={true}
            onRowSelected={this.rowSelected.bind(this)}
            onFirstDataRendered={this.onDataRendered}
            onComponentStateChanged={this.onDataRendered} />
        </div>
        <div style={styles.tableFooter}>
          <div style={styles.showEnabledSelect}>
            <SelectField
              value={this.state.showOnlyEnabledFieldValue}
              onChange={this.handleChangeShowOnlyEnabled.bind(this)}
              floatingLabelText="Show Enabled"
              floatingLabelFixed={true}
            >
              {notifyMeShowEnabledFields}
            </SelectField>
          </div>
          <div style={styles.paginate}>
            <button onClick={() => this.paginate()}>{'Load More'}</button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    count: store.inventoryState.count,
    inventoryRecords: store.inventoryState.inventoryRecords,
    start: store.inventoryState.start,
    total: store.inventoryState.total,
    loading: store.inventoryState.loading,
    error: store.inventoryState.inventoryRecordsFailed,
    updateSuccess: store.inventoryState.inventoryRecordsUpdateSuccess
  };
};

export const InventoryRecordsSearchResults = connect(mapStateToProps)(InventoryRecordsResultsContainer);
